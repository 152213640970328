
export function pad(text: any, count: number, character: string = "0"): string {
    let result = text? text.toString():"";
    for(let i = count - result.length; i > 0; --i) {
        result = character + result;
    }
    return result;
}

export function paragraphize(text: string|Array<string>) : string|null {
    if(text) {
        let atext: Array<string> = Array.isArray(text)? text:text.split("\n");
        return atext.map((p: string) => {
            return "<p>" + p + "</p>";
        }).join("");
    }
    else {
        return null;
    }
}
