import {Color} from "@/types";

export default <Array<Color>> [
    {
        id: 0,
        code: "#707455"
    },
    {
        id: 1,
        code: "#FBFD00"
    },
    {
        id: 2,
        code: "#FB0000"
    },
    {
        id: 3,
        code: "#FB21FB"
    },
    {
        id: 4,
        code: "#3AC6DE"
    },
    {
        id: 5,
        code: "#000000"
    },
    {
        id: 6,
        code: "#E96E1C"
    },
    {
        id: 7,
        code: "#FBFDFB"
    },
    {
        id: 8,
        code: "#0000FB"
    }
];
