import {Character} from "@/types";

export default <Array<Character>>[
    {
        id: 0,
        name: "Armelle",
        defaultColor: 0
    },
    {
        id: 1,
        name: "Carole",
        defaultColor: 1
    },
    {
        id: 2,
        name: "Cédric",
        defaultColor: 2
    },
    {
        id: 3,
        name: "Christine",
        defaultColor: 3
    },
    {
        id: 4,
        name: "Etienne",
        defaultColor: 4
    },
    {
        id: 5,
        name: "François",
        defaultColor: 5
    },
    {
        id: 6,
        name: "Hermeline",
        defaultColor: 6
    },
    {
        id: 7,
        name: "Pierre",
        defaultColor: 7
    },
    {
        id: 8,
        name: "Robert",
        defaultColor: 8
    }
];
