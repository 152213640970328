
import {Component, Vue, Prop} from 'vue-property-decorator';
import {Character, Color, Jetpack, PlayerConfiguration} from "@/types";
import characters from "@/data/characters";
import jetpacks from "@/data/jetpacks";
import colors from "@/data/colors";
import Action from "@/components/Action.vue";
import {pad} from "@/helpers/helpers";

enum ConfigureStep {
    player = "player",
    jetpack = "jetpack",
    color = "color"
}

@Component({
    name: "Configure",
    components: {Action},
})
export default class Configure extends Vue {
    // props
    @Prop(String) test?: string;

    // data
    step = ConfigureStep.player;
    configuration = this.$store.state.configuration;
    characterSampleWidth: number = 1;

    // computed
    get invitation(): string {
        switch(this.step) {
            case ConfigureStep.player:
                return "<div><b>Choisis</b> ton personnage</div>";
            case ConfigureStep.jetpack:
                return "<div><b>Choisis</b> ton jetpack</div>";
            case ConfigureStep.color:
                return "<div><b>Choisis</b> ta couleur</div>";
        }
    }

    get characters(): Array<Character> {
        return characters;
    }

    get jetpacks(): Array<Jetpack> {
        return jetpacks;
    }

    get colors(): Array<Color> {
        return colors;
    }

    get characterStyle(): any {
        switch(this.step) {
            case ConfigureStep.player:
                return {
                    backgroundImage: "url(/characters/Portraits/" + pad(this.configuration.characterId + 1, 2) + ".jpg)"
                };
            case ConfigureStep.color:
                return {
                    backgroundImage: "url(/characters/" + pad(this.configuration.characterId + 1, 2) + "/" + pad(this.configuration.color, 4) +".png)"
                };
            case ConfigureStep.jetpack:
                return {
                    backgroundImage: "url(/characters/" + pad(this.configuration.characterId + 1, 2) + "/" + pad((this.configuration.jetpackId + 1) * 9 + this.configuration.color, 4) +".png)"
                };
        }
    }

    get characterSampleStyle(): any {
        return {
            height: this.characterSampleWidth + "px"
        };
    }

    // methods
    goback() {
        switch (this.step) {
            case ConfigureStep.player:
                this.$router.push({name: "home"});
                break;
            case ConfigureStep.color:
                this.step = ConfigureStep.player;
                break;
            case ConfigureStep.jetpack:
                this.step = ConfigureStep.color;
                break;
        }
    }

    nextstep() {
        switch (this.step) {
            case ConfigureStep.player:
                this.step = ConfigureStep.color;
                break;
            case ConfigureStep.color:
                this.step = ConfigureStep.jetpack;
                break;
            case ConfigureStep.jetpack:
                this.$store.commit("setConfiguration", this.configuration);
                this.$router.push({name: "play"});
                break;
        }
    }

    chooseCharacter(character: Character) {
        this.configuration.characterId = character.id;
        this.configuration.color = character.id;
    }

    chooseJetpack(jetpack: Jetpack) {
        this.configuration.jetpackId = jetpack.id;
    }

    chooseColor(color: Color) {
        this.configuration.color = color.id;
    }

    characterBoxStyle(character: Character): any {
        return {
            backgroundImage: "url(/characters/Portraits/" + pad(character.id + 1, 2) + ".jpg)"
        };
    }
    jetpackBoxStyle(jetpack: Jetpack): any {
        return {
            backgroundImage: "url(/characters/Jetpack/" + pad(jetpack.id, 4) + ".png)"
        };
    }

    // watch

    // hooks
    mounted() {
        if(this.$refs.characterSample) {
            this.characterSampleWidth = (<Element>this.$refs.characterSample).getBoundingClientRect().width;
        }
    }
}
